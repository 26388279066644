import React from "react";
import anime from 'animejs/lib/anime.es.js';
import './Personal.css';

class Animation extends React.Component{

    constructor(props){
        super(props);
        this.animatedGridRef = React.createRef();
        
    }

    handleResize = () =>{
        this.setState({
            animatedGridWidth : this.animatedGridRef.current.offsetWidth,
            animatedGridHeight : this.animatedGridRef.current.offsetHeight
        });
    }

    componentDidMount(){
        this.setState({
            animatedGridWidth : this.animatedGridRef.current.offsetWidth,
            animatedGridHeight : this.animatedGridRef.current.offsetHeight
        });
        
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
    }

    setAnimatedGridRef = element =>{
        this.animatedGridRef.current = element;
    }


    onCircleClick = () =>{

        anime({
            targets: '.circle',
            translateX: "35em",
            easing: 'cubicBezier(.56,-0.31,.77,.94)',
            duration : 1500,
            complete: () =>{
                document.getElementById('animatedGrid').style.display = 'grid';
                anime({
                    targets: '#animatedGrid',
                    opacity: 1,
                    duration: 2000,
                    easing: 'linear',
                });
                this.setState({
                    animatedGridWidth : this.animatedGridRef.current.offsetWidth,
                    animatedGridHeight : this.animatedGridRef.current.offsetHeight
                });
            }
        });
    }

    calculateItemSize = ()=> this.state.animatedGridWidth >= this.state.animatedGridHeight?this.state.animatedGridHeight/6:this.state.animatedGridWidth/9;

    gridItemsHoverAnim = (childId) =>{
        const colCount = 9;
        const row = Math.floor(childId/colCount);
        const col = childId-(colCount*row);
        
        let itemHovered = document.getElementsByClassName("animatedGridItem")[childId];
        let itemSize = this.calculateItemSize();

        itemHovered.style.height = itemSize+"px";
        itemHovered.style.width = itemSize+"px";
        itemHovered.style.rotate = "180deg";

        if(col-1 >=0){
            let leftItem = document.getElementsByClassName("animatedGridItem")[childId-1];
            leftItem.classList.add("secondaryHoverItem");
            leftItem.style.width = itemSize/1.25+"px";
            leftItem.style.height = itemSize/1.25+"px";
        }
        if(col !== colCount-1){
            let rightItem = document.getElementsByClassName("animatedGridItem")[childId+1];
            rightItem.classList.add("secondaryHoverItem");
            rightItem.style.width = itemSize/1.25+"px";
            rightItem.style.height = itemSize/1.25+"px";
        }
        if(row !== 0){
            let topItem = document.getElementsByClassName("animatedGridItem")[childId-9];
            topItem.classList.add("secondaryHoverItem");
            topItem.style.width = itemSize/1.25+"px";
            topItem.style.height = itemSize/1.25+"px";
        }
        if(row !== 5){
            let bottomItem = document.getElementsByClassName("animatedGridItem")[childId+9];
            bottomItem.classList.add("secondaryHoverItem");
            bottomItem.style.width = itemSize/1.25+"px";
            bottomItem.style.height = itemSize/1.25+"px";
        }
    }

    gridItemsOnMouseLeaveAnim = (childId) =>{
        const colCount = 9;
        const row = Math.floor(childId/colCount);
        const col = childId-(colCount*row);

        let itemHovered = document.getElementsByClassName("animatedGridItem")[childId];

        itemHovered.style.height = null;
        itemHovered.style.width = null;
        itemHovered.style.rotate = null;
        
        if(col !== 0){
            let leftItem = document.getElementsByClassName("animatedGridItem")[childId-1];
            leftItem.classList.remove("secondaryHoverItem");
            leftItem.style.width = null;
            leftItem.style.height = null;
        }
        if(col !== colCount-1){
            let rightItem = document.getElementsByClassName("animatedGridItem")[childId+1];
            rightItem.classList.remove("secondaryHoverItem");
            rightItem.style.width = null;
            rightItem.style.height = null;
        }
        if(row !== 0){
            let topItem = document.getElementsByClassName("animatedGridItem")[childId-9];
            topItem.classList.remove("secondaryHoverItem");
            topItem.style.width = null;
            topItem.style.height = null;
        }
        if(row !== 5){
            let bottomItem = document.getElementsByClassName("animatedGridItem")[childId+9];
            bottomItem.classList.remove("secondaryHoverItem");
            bottomItem.style.width = null;
            bottomItem.style.height = null;
        }
    }

    createGridItems = (gridItemsCount) =>{
        let childs = new Array(gridItemsCount);
        for(let i = 0; i<childs.length; i++){
            childs[i] = 
            <div key={i} className="gridItemHolder" onMouseOver={() => this.gridItemsHoverAnim(i)} onMouseOut={() => this.gridItemsOnMouseLeaveAnim(i)}>
                <img className="animatedGridItem" src={'./img/star-icon.webp'} alt="star"></img>
            </div>;
        }
        return childs;
    }

    render(){
        return(
        <div id="animationWrap">
            <div id='gameContainer'>
                <div className="circle" >
                    <div onClick={this.onCircleClick}>
                        <span id="btnPlay"></span>
                    </div>
                </div>
                <div id="animatedGrid" ref={this.setAnimatedGridRef}>{this.createGridItems(54)}</div>
            </div>
        </div>);
    }
}

export default Animation;