import React from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';

class Path extends React.Component{
    render(){
        return(
            <Row className="pt-5">
                <Col>
                    <h2>Trayectoria</h2>
                    <ListGroup className="p-2">
                        <ListGroup.Item>Título de la  ESO (2014-2018)</ListGroup.Item>
                        <ListGroup.Item>Título de Bachillerato de ciencias (2018-2020)</ListGroup.Item>
                        <ListGroup.Item>Licencia de conducción (Tipo B)</ListGroup.Item>
                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item eventKey="0" style={{borderTopLeftRadius:0,borderTopRightRadius:0}}>
                                    <Accordion.Header>Título de Desarrollador de Aplicaciones Multiplataforma (2020-2022)</Accordion.Header>
                                    <Accordion.Body>
                                        <h3>1º Curso</h3>
                                        <div className="grid-path-items">
                                            <img className="path-item" src="./img/competencias/java-icon.webp" alt="java"/>
                                            <img className="path-item" src="./img/competencias/html-icon.webp" alt="html"/>
                                            <img className="path-item" src="./img/competencias/css-icon.webp" alt="css"/>
                                            <img className="path-item" src="./img/competencias/mysql-icon.webp" alt="mysql"/>
                                            <img className="path-item" src="./img/competencias/mariadb-icon.webp" alt="mariadb"/>
                                            <img className="path-item" src="./img/competencias/xml-icon.webp" alt="xml"/>
                                        </div>
                                        <div className="grade-achievements">
                                            <ul className="list-path-items">
                                                <li>Conocimientos de <b>POO</b> (Programación Orientada a Objetos) del lenguaje de programación <strong>Java</strong>.</li>
                                                <li>Aprendizaje sobre <strong>HTML y CSS</strong> (Propiedades básicas, display, posicionamiento, selectores, modelo de caja, pseudoclases).</li>
                                                <li>Diseño conceptual, lógico, físico de bases de datos, realización de sentencias DDL, DML, DCL, exportación e importación de datos y copias de seguridad del sistema gestor de base de datos <strong>MySQL/MaríaDB</strong>.</li>
                                                <li>Manejo de <strong>XML</strong>, construcción de <strong>DTD</strong>, <strong>esquemas XML</strong> y <strong>XSL</strong> (XPath y XSLT).</li>
                                                <li>Conocimientos sobre redes y subredes, máquinas virtuales, protocolos de red, servidores, realización de copias de seguridad, instalación de sofware y 
                                                hardware en sistemas informáticos y construcción de scripts básicos de Windows y Linux.</li>
                                            </ul>
                                        </div>
                                        <div className="holidays">
                                            <h4>Vacaciones</h4>
                                            <div className="holidays-path-items">
                                                <img className="path-item" src="./img/competencias/html-icon.webp" alt="html"/>
                                                <img className="path-item" src="./img/competencias/css-icon.webp" alt="css"/>
                                                <img className="path-item" src="./img/competencias/js-icon.webp" alt="javascript"/>
                                                <ul className="pt-3 list-path-items">
                                                    <li>Profundización en <b>HTML</b> y <b>CSS</b>. (flexbox, grid, animaciones, media queries...)</li>
                                                    <li>Aprendizaje de <strong>Javascript</strong> a nivel junior. (Hasta objetos)</li>
                                                    <li>Creación de un servidor web Apache para alojar mi primera página web.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h3 className="pt-3">2º Curso</h3>
                                        <div className="grid-path-items">
                                            <img className="path-item" src="./img/competencias/java-icon.webp" alt="java"/>
                                            <img className="path-item" src="./img/competencias/mysql-icon.webp" alt="mysql"/>
                                            <img className="path-item" src="./img/competencias/xml-icon.webp" alt="xml"/>
                                            <img className="path-item" src="./img/competencias/json-icon.webp" alt="json"/>
                                            <img className="path-item" src="./img/competencias/android_studio-icon.webp" alt="android studio"/>
                                            <img className="path-item" src="./img/competencias/kotlin-icon.webp" alt="kotlin"/>
                                            <img className="path-item" src="./img/competencias/sqlite-icon.webp" alt="sqlite"/>
                                            <img className="path-item" src="./img/competencias/python-icon.webp" alt="python"/>
                                            <img className="path-item" src="./img/competencias/c_sharp-icon.webp" alt="c#"/>
                                            <img className="path-item" src="./img/competencias/dotnet-icon.webp" alt=".NET"/>
                                            <img className="path-item" src="./img/competencias/unity-icon.webp" alt="unity"/>
                                            <img className="path-item" src="./img/competencias/odoo-icon.webp" alt="odoo"/>
                                        </div>
                                        <div className="grade-achievements">
                                            <ul className="list-path-items">
                                                <li>Conocimientos sobre el desarrollo de <strong>aplicaciones Android utilizando Java y Kotlin</strong>.</li>
                                                <li>Utilización de framework .Net Core mediante <strong>WPF</strong> usando <b>C#</b> como lenguaje de programación.</li>
                                                <li>Profundización en <b>Java</b>, nociones básicas en <b>estructuras de datos</b> (Lista doblemente enlazada, lista genérica/lista genérica parametrizada, 
                                                árbol binario...), conexión y gestión de sentencias a la <b>base de datos</b> y <b>programación de servicios y procesos</b> (procesos, hilos, tareas asíncronas usando hilos 
                                                controlados por semáforos o otros controladores, programación de un servidor web...).</li>
                                                <li>Capacidad de crear un juego 2D o 3D en <b>Unity</b> con características básicas. (Movimiento, interacción con objetos del entorno, gravedad, gestión de animaciones...)</li>
                                                <li>Conocimientos sobre la utilización de la aplicación de gestión empresarial <strong>Odoo</strong>.</li>
                                            </ul>
                                        </div>
                                        <div className="holidays">
                                            <h4>Vacaciones</h4>
                                            <div className="holidays-path-items">
                                                <img className="path-item" src="./img/competencias/flutter-icon.webp" alt="javascript"/>
                                                <img className="path-item" src="./img/competencias/nodejs-icon.webp" alt="nodeJS"/>
                                                <img className="path-item" src="./img/competencias/react-icon.webp" alt="react"/>
                                                <img className="path-item" src="./img/competencias/bootstrap-icon.webp" alt="bootstrap"/>
                                                <ul className="pt-3 list-path-items">
                                                    <li>Acercamiento en la utilización de <b>Flutter</b> para la elaboración de aplicaciones desde una misma base de código.</li>
                                                    <li>Aprendizaje sobre la biblioteca de Javascript <b>ReactJS</b> y <strong>Bootstrap</strong> para la realización de <a href="./">esta aplicación web</a>.</li>
                                                    <li>Acercamiento al entorno de <b>NodeJS</b> enfoncado para el alojamiento de un aplicación web.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                    </ListGroup>
                </Col>
            </Row>
        );
    }
}

export default Path;
