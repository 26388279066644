import Card from 'react-bootstrap/Card';
import React from 'react';

function Peliculas(){

    const peliculas =[
        {
            img: './img/peliculas/Taxi_driver.webp',
            titulo: 'Taxi Driver'
        },
        {
            img: "./img/peliculas/El_efecto_mariposa.webp",
            titulo: "El efecto mariposa"
        },
        {
            img: "./img/peliculas/Dallas_Buyers_Club.webp",
            titulo: "Dallas Buyers Club"
        },
        {
            img: "./img/peliculas/Requiem_for_a_dream.webp",
            titulo: "Requiem for a dream"
        },
        {
            img: "./img/peliculas/Diario_de_un_rebelde.webp",
            titulo: "Diario de un rebelde"
        },
        {
            img: "./img/peliculas/El_indomable_Will_Hunting.webp",
            titulo: "El indomable Will Hunting"
        }
    ];

    let onClickCard = (index) =>{
        window.open("https://www.google.com/search?q=" + peliculas[index].titulo, '_blank')
    }

    return(
        <div className="movies">
            {peliculas.map((pelicula,index) => 
            <Card bg='light' text='dark' key={index} className='movieCard' onClick={() => onClickCard(index)}>
                <Card.Img variant='top' src={pelicula.img} alt={pelicula.titulo}/>
                <Card.Body>
                    <Card.Title className='tituloPelicula' title={pelicula.titulo}>{pelicula.titulo}</Card.Title>
                </Card.Body>
            </Card>)
            }
        </div>
    );
}

export default Peliculas;