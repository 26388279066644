import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router-dom';
import './NavBar.css';

function NavBar() {
  const location = useLocation();
  
  return (
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand id="navBarBrand" href="./"><img src={'./img/star-icon.webp'} height={'50px'} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls='navBarCollapse' />
        <Navbar.Collapse id="navBarCollapse">
          <Nav fill activeKey={location.pathname}>
            <Nav.Link eventKey='/' href="/">Inicio</Nav.Link>
            <Nav.Link eventKey='/habilidades' href="/habilidades">Habilidades</Nav.Link>
            <Nav.Link eventKey='/personal' href="/personal">Personal</Nav.Link>
            <Nav.Link eventKey='/contacto' href="/contacto">Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}


export default NavBar;
