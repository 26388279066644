import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Typed from 'typed.js';
import React from 'react';
import './Home.css';

function Presentation(){

    const el = React.useRef(null);
    const typed = React.useRef(null);

    React.useEffect(() => {
        const options = {
            strings: [
            'Ismael Rodríguez Rivas',
            '<i>Ismael Rodríguez Rivas</i>',
            'Ismael Rodríguez Rivas',
          ],
          typeSpeed: 100,
          backSpeed: 50,
        };

        // elRef refers to the <span> rendered below
        if(window.innerWidth>576){
            typed.current = new Typed(el.current, options);
            return () => {
                // Make sure to destroy Typed instance during cleanup
                // to prevent memory leaks
                typed.current.destroy();
            }
        }
        
        
      }, [])

    return(
        <Row className='align-items-center justify-content-center flex-wrap-reverse'>
                <Col className='col-12 col-lg-7'>
                    <Row>
                        <div className="type-wrap pb-3">
                            <h1 ref={el}>Ismael Rodríguez Rivas</h1>
                        </div>
                        <p className='pt-1'>Soy un programador poco experimentado pero con pasión por la programación y ganas de aprender nuevas tecnologías.
                        Actualmente tengo {Math.floor((new Date().getTime() - new Date("08/05/2002 18:00:00").getTime())/31557600000)} años y en mi periodo formativo he desarrollado más habilidades de cara al desarrollo de aplicaciones multiplataforma para móviles y de escritorio,
                        aunque estoy interesado en aprender desarrollo web.</p>
                    </Row>
                    <Row>
                        <p className='pt-1'>En cuanto a las facetas de programador, me gusta trabajar del lado del cliente y del servidor, 
                        aunque actualmente estoy más enfocado en la parte del cliente aprendiendo ReactJS planeo estudiar más a fondo la 
                        faceta de cara al servidor aprendiendo NodeJS.</p>
                    </Row>
                </Col>
                <Col className='col-9 col-md-6 col-lg-5 pb-4 pb-lg-0'>
                    <img id="imgMain" src='./img/astronaut.webp' alt='astronaut'/>
                </Col>
            </Row>
    );
}

export default Presentation;
