import React from "react";
import { Row } from "react-bootstrap";
import './NotFound.css';

function NotFound() {
    return (
        <Row className="justify-content-center align-items-center notFoundContainer">
            <h2 style={{ textAlign: "center", zIndex: '500'}}>Vaya <span role="img" aria-label='thinking-face'>🤔</span>, parece que esta página no está disponible</h2>
            <div id="circle-orbit-container">
                <img src="./img/planet.webp" alt="planet" style={{maxWidth: '30em'}}/>
                <div id="outer-orbit">
                    <img src="./img/rocket.webp" alt="rocket"/>
                </div>
            </div>
        </Row>
    );
}

export default NotFound;