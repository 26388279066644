import './Home.css';
import React from 'react';
import Presentation from './Presentation';
import Path from './Path';

function Home(){
    return(
        <>
            <Presentation/>
            <Path/>
        </>
    );
}

export default Home;
