import React from 'react';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import Skills from './components/Skills/Skills';
import Personal from './components/Personal/Personal';
import Container from 'react-bootstrap/Container';
import Contact from './components/Contact/Contact';
import NotFound from './components/NotFound/NoFound';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';


function App() {

    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<AppStandarStructure component={<Home/>}/>} />
                <Route path='/habilidades' element={<AppStandarStructure component={<Skills/>}/>} />
                <Route path='/personal' element={<AppStandarStructure component={<Personal/>}/>} />
                <Route path='/contacto' element={<NoContainerStandarStructure component={<Contact/>}/>} />
                <Route path='*' element={<NoContainerStandarStructure component={<NotFound/>}/>} />
            </Routes>
        </Router>
    );
}

function AppStandarStructure(props) {
    return (
        <Container fluid className='appContent'>
            <NavBar />
            <Container className='pb-5 pt-4'>
                {props.component}
            </Container>
        </Container>
    );
}

function NoContainerStandarStructure(props) {
    return (
        <Container fluid className='appContent'>
            <NavBar />
            {props.component}
        </Container>
    );
}

export default App;
