import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './Skills.css';

const skills = {
    Java: [<ul><li>Conocimientos de <b>POO</b> (Programación Orientada a Objetos).</li>
        <li>Lectura/Escritura de <b>archivos</b>.</li><li>Conexión y gestión de <b>base de datos</b>.</li>
        <li><b>Estructuras de datos</b> (Lista doblemente enlazada, lista genérica/lista genérica parametrizada, árbol binario...).</li>
        <li><b>Programación de servicios y procesos</b> (procesos, hilos, tareas asíncronas usando hilos controlados por semáforos o otros controladores, programación de un servidor web...).</li></ul>, 80],
    JS: [<ul><li>Conocimientos de <b>POO</b> (Programación Orientada a Objetos).</li><li>Interacción con el <strong>DOM</strong>.</li>
        <li>Manejo de <b>eventos</b>.</li><li>Programación asíncrona usando <strong>AJAX</strong>.</li></ul>, 70],
    React: [<ul><li>JSX.</li><li>Componentes y propiedades.</li><li>Estado y manejo de eventos.</li></ul>, 60],
    NodeJS: [<ul><li>Conceptos básicos.</li><li>Importación y exportación de módulos.</li><li>Utilizar el módulo <strong>Express.js</strong> para la creación de un servidor web.</li></ul>, 40],
    Bootstrap: ["", 80],
    C_Sharp: [<ul><li>Conocimientos de <b>POO</b> (Programación Orientada a Objetos).</li><li>Conexión y gestión de <b>base de datos</b>.</li><li>Lectura/Escritura de <b>archivos</b>.</li></ul>, 60],
    Kotlin: [<ul><li>Conocimientos de <b>POO</b> (Programación Orientada a Objetos).</li></ul>, 50],
    Python: [<ul><li>Conocimientos de <b>POO</b> (Programación Orientada a Objetos).</li></ul>, 50],
    Html: ["", 100],
    Css: [<ul><li><b>Propiedades básicas</b>.</li><li><b>Visualización</b> y <b>posicionamiento</b> de elementos.</li><li>Tipos de <b>selectores</b>, <b>modelo de caja</b> y <b>pseudoclases</b>.</li>
        <li><strong>Flexbox</strong> y <strong>Grid</strong>.</li><li><strong>Media queries</strong> y <strong>animaciones</strong>.</li></ul>, 100],
    Flutter: [<ul><li>Conocimientos de <b>POO</b> (Programación Orientada a Objetos).</li><li>Manejo de <b>componentes básicos</b>.</li></ul>, 50],
    Unity: [<ul><li><b>Movimiento</b> y <b>salto</b> o salto doble.</li><li>Propiedades de <strong>físicas</strong> y <strong>gravedad</strong>.</li><li>Manejo de <strong>sprites</strong> de personajes y <strong>animaciones</strong>.</li>
        <li>Control de <b>escenas</b>, <b>eventos</b> y generación de terreno mediante <strong>tilemaps</strong>.</li></ul>, 70],
    DotNet: [<ul><li>WPF.</li></ul>, 60],
    Odoo: [<ul><li>Instalación de Odoo.</li><li>Creación/Modificación/Eliminación de módulos.</li><li>Importación/Exportación de datos.</li></ul>, 50],
    MySQL: [<ul><li>Diseño conceptual/lógico/fisico de base de datos.</li><li>Sentencias DDL, DML, DCL.</li><li>Exportación e importación de datos y copias de seguridad.</li></ul>, 80],
    MariaDB: [<ul><li>Diseño conceptual/lógico/fisico de base de datos.</li><li>Sentencias DDL, DML, DCL.</li><li>Exportación e importación de datos y copias de seguridad.</li></ul>, 80],
    SQLite: [<ul><li>Diseño conceptual/lógico/fisico de base de datos.</li><li>Sentencias DDL, DML, DCL.</li></ul>, 70],
    Json: ["", 100],
    Xml: [<ul><li>Conocimientos sobre XMLs bien formados.</li><li><strong>DTD</strong>.</li><li><strong>Esquemas XML</strong>.</li><li><strong>XSL</strong> (XPath y XSLT).</li></ul>, 100]
};

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.value === 'C_Sharp' ? 'C#' : props.value.replace('_', ' ')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Conocimientos</h4>
                {skills[props.value][0]}
                <ProgressBar now={skills[props.value][1]} label={skills[props.value][1] + '%'} style={{ marginInline: '2rem' }} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

class Skills extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            skillSelected: 'Java',
        };
    }

    handleClickSkill = (skill) => {
        this.setState({
            skillSelected: skill,
            modalShow: true,
        });
    }

    render() {
        return (
            <>
                <div id='skillsContainer'>
                    {(() => {
                        let cards = [];
                        for (let i = 0; i < Object.keys(skills).length; i++) {
                            cards.push(
                                <Card className='card-skill' text='dark' bg='light' key={Object.keys(skills)[i]} onClick={() => this.handleClickSkill(Object.keys(skills)[i])}>
                                    <Card.Img className='card-img-top-skill' variant='top' src={`./img/competencias/${Object.keys(skills)[i].toLowerCase()}-icon.webp`}  alt={Object.keys(skills)[i]}/>
                                    <Card.Body>
                                        <Card.Title className='card-title-skill'>{Object.keys(skills)[i] === 'C_Sharp' ? 'C#' : Object.keys(skills)[i].replace('_', ' ')}</Card.Title>
                                    </Card.Body>
                                </Card>
                            );
                        }
                        return cards;
                    })()}
                </div>
                <MyVerticallyCenteredModal value={this.state.skillSelected} show={this.state.modalShow}
                    onHide={() => this.setState({
                        modalShow: false,
                    })} />
            </>
        );
    }
}

export default Skills;