import {Row,Col} from 'react-bootstrap';
import React from 'react';
import './Personal.css';
import Animation from './Animation';
import Peliculas from './Peliculas';
import Canciones from './Canciones';

function Personal(){

    let bounceLetters = (string) =>{
        let letters = string.split('');
        
        for(let index in letters){
            if(letters[index] !== ' '){
                letters[index] = <span key={index} className='bounceAnimSpan' style={{'animationDelay': 'calc(0.1s * '+(parseInt(index)+1)+')'}}>{letters[index]}</span>;
            }
        }
        return letters;
    }

    return(
        <>
            <Row className='align-items-center justify-content-center flex-wrap-reverse'>
                <Col className='col-12'>
                    <p>Hola <span role="img" aria-label='smile'>😁</span>, si has entrado en este apartado del portfolio es porque te interesa mi personalidad, 
                    hobbies, aspiraciones y demás. A parte de hablar de mi quiero destinar este apartado para dar rienda suelta a mi imaginación.
                    </p>
                </Col>
                <Col className='pb-4 pb-lg-0'>
                    <div className='datosPersonales'>
                        <ul className='listaDatos'>
                            <li><span>Edad:</span> {Math.floor((new Date().getTime() - new Date("08/05/2002 18:00:00").getTime())/31557600000)} años</li>
                            <li><span>Teléfono:</span> 628614996</li>
                            <li onClick={() => window.location.href = "mailto:rodriguezrivasismael@gmail.com"} style={{cursor:'pointer'}}><span>Correo:</span> rodriguezrivasismael@gmail.com</li>
                        </ul>
                    </div>
                </Col>
                <Col className='col-9 col-md-12 col-lg-7 pb-4 pb-lg-0'>
                    <div className='imgPersonalContainer'>
                        <img id='imgPersonal' src='./img/foto-perfil.webp' alt='Ismael Rodríguez Rivas'/>
                    </div>
                </Col>
            </Row>
            <Row>
                <p>En cuanto a mis hobbies, me gusta crear animaciones al hacer sitios web para darle algo de dinamísmo visual, por ahora son bastante simples pero espero que en un futuro sean mucho mejores,
                 aquí dejo un ejemplo de animación creada por mí:</p>
                <Animation/>
                <p>Suelo pasar tiempo con mis gatos.</p>
                <div className='imgGatos'>
                    <img src='./img/michi.webp' alt="michi"/>
                    <img src='./img/michi-eu.webp' alt="/bakis"/>
                </div>
                <p>{bounceLetters("Juego a videojuegos en mis ratos libres.")}</p>
                <div className='imgGames'>
                    <img src='./img/mario_bros.gif' alt='mario bros gif'/>
                    <img src='./img/dark-souls-bonfire.gif' alt='dark souls bonfire'/>
                </div>
                <p>También me gusta mucho ver películas cuando tengo un rato libre, ya sea en el cine o en el sofá de casa, estás son las películas que he visto y que
                más me han gustado últimamente.</p>
                    <Peliculas/>
                <p>Y sobre todo me encanta escuchar música, paso casi todo el día con música puesta, estas son algunas de las canciones que 
                más estoy escuchando.</p>
                    <Canciones/>
            </Row>
        </>
    );
}

export default Personal;