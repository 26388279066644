import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import './Contact.css';

function Contact() {

    const nombreRef = useRef();
    const emailRef = useRef();
    const mensajeRef = useRef();
    const alertRef = useRef();

    const validateData = () => {
        const nombre = nombreRef.current.value.trim();
        const email = emailRef.current.value.trim();
        const mensaje = mensajeRef.current.value.trim();

        nombreRef.current.classList.remove('form_div-input-error');
        emailRef.current.classList.remove('form_div-input-error');
        mensajeRef.current.classList.remove('form_div-input-error');

        if (nombre.length === 0 || email.length === 0 || mensaje.length === 0) {
            showAlert('Los campos no pueden estar vacios');
            if (nombre.length === 0) {
                nombreRef.current.classList.add('form_div-input-error');
            }
            if (email.length === 0) {
                emailRef.current.classList.add('form_div-input-error');
            }
            if (mensaje.length === 0) {
                mensajeRef.current.classList.add('form_div-input-error');
            }
            return false;
        }

        if (nombre.length <= 1) {
            nombreRef.current.classList.add('form_div-input-error');
            showAlert('El nombre no es válido');
            return false;
        } else if (nombre.length > 40) {
            nombreRef.current.classList.add('form_div-input-error');
            showAlert('El nombre no puede tener más de 40 caracteres');
            return false;
        }

        let validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!validRegex.test(email)) {
            emailRef.current.classList.add('form_div-input-error');
            showAlert('El correo no es válido');
            return false;
        }

        return true;
    }

    const showAlert = (text) => {
        alertRef.current.innerHTML = text;
        alertRef.current.style.display = 'block';
        setTimeout(() => alertRef.current.style.display = 'none', 4000);
    }


    const sendEmail = (e) => {
        e.preventDefault();
        if (validateData()) {
            document.getElementById('emailImg').animate([
                { transform: 'translateY(0)' },
                { transform: 'translateY(-50vh)' }
            ], {
                duration: 1000,
                iterations: 1,
                easing: 'cubic-bezier(.72,-0.3,1,1)',
                fill: 'forwards'
            }).finished.then(() => {
                emailjs.sendForm(
                    'service_cdkapyf',
                    'template_kh4k0cl',
                    e.target,
                    'TeXFshJtaBUbzhNBm'
                ).then(res => {
                    document.getElementById('emailImg').animate([
                        { transform: 'translateY(-50vh)' },
                        { transform: 'translateY(0)' }
                    ], {
                        duration: 1000,
                        iterations: 1,
                        easing: 'ease-in',
                        fill: 'forwards'
                    }).finished.then(() => {
                        if (res.status === 200 && res.text === 'OK') {
                            nombreRef.current.value = '';
                            emailRef.current.value = '';
                            mensajeRef.current.value = '';
                            showAlert('Mensaje enviado correctamente');
                        } else {
                            showAlert('Ha ocurrido un error al enviar el correo');
                        }
                    });
                }).catch(err => console.error(err));
            });
        }
    }

    return (
        <div className="formContainer">
            <form onSubmit={sendEmail}>
                <div>
                    <h2 className="encabezadoForm">Contáctame</h2>
                    <img id="emailImg" src="./img/email-icon.webp" alt="" />
                </div>
                <div className="form_div">
                    <input ref={nombreRef} name='name' className="form_div-input" type="text" placeholder="Nombre" required maxLength={40} />
                    <input ref={emailRef} name='email' className="form_div-input" type="email" placeholder="Email" required maxLength={130} />
                    <textarea ref={mensajeRef} name='message' className="formMessage" placeholder="Mensaje" required maxLength={300} />
                </div>
                <input type="submit" id="btnEnviar" className="primaryButton" value="Enviar" />
                <span ref={alertRef} className="contactFormAlert"></span>
            </form>
        </div>
    );
}

export default Contact;