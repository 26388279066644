import React from "react";
import { Card,Button } from "react-bootstrap";

function Canciones(){
    
    class Cancion {
        constructor(nombre, actor, linkCancion, linkArtista, img) {
            this.nombre = nombre;
            this.actor = actor;
            this.linkCancion = linkCancion;
            this.linkArtista = linkArtista;
            this.img = img;
        }
    }

    const canciones = [
        new Cancion("My Hero", "Foo Fighters", 'https://open.spotify.com/track/4dVbhS6OiYvFikshyaQaCN?si=b7dff1b354c14f7a', 'https://open.spotify.com/artist/7jy3rLJdDQY21OgRLCZ9sD?si=3f088c43cff046e5', './img/canciones/my_hero.webp'),
        new Cancion("Ben Peinados", "Terbutalina", 'https://open.spotify.com/track/00k3PScY086dUWFht2UrrA?si=640dcf8b60c94859', 'https://open.spotify.com/artist/3sApRiClbyMfnz5AmwG46A?si=7dac1fc40b7a42b1',"./img/canciones/ben_peinados.webp"),
        new Cancion("Tubthumper", "Chumbawamba", 'https://open.spotify.com/track/5YScXJKtefsgdskIy60N7A?si=0aaac47df9b34aa1', 'https://open.spotify.com/artist/0TcYeHEK9sBtv7xPbKhzHz?si=3665c20dff5e4e93', './img/canciones/tubthumper.webp'),
        new Cancion("Toothpick", "DIRK.", 'https://open.spotify.com/track/6ODZd2ehNzWbzNBJi0lu5s?si=734fbfa69e104c61',  'https://open.spotify.com/artist/0TqNtgcRhRduvY1cc9MKFm?si=afb101f3fff84a9a', './img/canciones/toothpick.webp'),
        new Cancion("515T3M45", "Brokesus", 'https://open.spotify.com/track/0yByHeWRHeJ0KwRGQgskpD?si=f0a97b32668347ea', 'https://open.spotify.com/artist/3aEFLeqVGaMwtAcIiJwLin?si=86aa9974346a4c9f', './img/canciones/515T3M45.webp'),
        new Cancion("Cyberpunk", "Faxu", 'https://open.spotify.com/track/1JXuTH5DUO3CVxMVTndnNf?si=9b3c7f75c3304dda', 'https://open.spotify.com/artist/0LA5aboyOdxBzRiQGrnLdF?si=f1efc26c0cc24b27',"./img/canciones/cyberpunk.webp")
    ];

    return(
        <div className="canciones">
            {canciones.map((cancion) =>
            <Card key={cancion.nombre} className='songCard' bg="light" text="dark">
                <Card.Img src={cancion.img} alt={cancion.nombre}></Card.Img>
                <Card.Body className="songCardBody">
                    <Card.Title className="tituloCancion">{cancion.nombre}</Card.Title>
                    <Card.Subtitle className="autorCancion" onClick={() => window.open(cancion.linkArtista, '_blank')}>{cancion.actor}</Card.Subtitle>
                    <Button variant="success" className="spotifyButton" onClick={() => window.open(cancion.linkCancion, '_blank')}>Ver en spotify</Button>
                </Card.Body>
            </Card>)
            }
        </div>
    );
}

export default Canciones;